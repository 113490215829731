import { Attachment, IAttachment } from './attachments';
import { IAuthor } from './author';
import { Dates, IDates } from './dates';
import { IPlatform, Platform } from './operation';
import { ICustomFieldsValue } from './project.model';

export interface IMapGroup {
  group_id: string;
  maps: Array<any>;
  isStarred: boolean;
}

export class MapGroup implements IMapGroup {
  public group_id: string;
  public maps: Array<any> = [];
  public isStarred: boolean;
  public database: string;
  public projectName: string;
  public group: string;
  constructor(data: any = {}) {
    this.group_id = data.groupId || '';
    this.group = data.group || '';
    this.maps.push(data);
    this.isStarred = false;
    this.database = data.database || '';
    this.projectName = data.projectName || '';
  }
}

export interface IMapArrangedByGroup {
  [name: string]: IMapGroup;
}
export interface IFilterListView {
  drawing: number;
  person: number;
  audit: number;
  tags: number;
  template: number;
}
export class FilterListView implements IFilterListView {
  public drawing: number;
  public person: number;
  public audit: number;
  public tags: number;
  public template: number;
  constructor(data: any = {}) {
    this.drawing = data.drawing || 5;
    this.person = data.person || 5;
    this.audit = data.audit || 5;
    this.tags = data.tags || 5;
    this.template = data.template || 5;
  }
}

export class PersonList {
  public userName: string;
  public isStarred: boolean;
  constructor(data: string = '') {
    this.userName = data || '';
    this.isStarred = false;
  }
}

/** Interface for ticket */
export interface ILastmodifier {
  type: string;
  email: string;
}

export class LastModifier implements ILastmodifier {
  public type: string;
  public email: string;
  constructor(data: any = {}) {
    this.type = data.type || 'IB.EdBundle.Document.Person';
    this.email = data.email || '';
  }
}

export class Author implements IAuthor {
  public type: string;
  public email: string;
  constructor(data: any = {}) {
    this.type = data.type || 'IB.EdBundle.Document.Person';
    this.email = data.email || '';
  }
}
export interface IContent {
  attachments: string[];
  lastmodifier: ILastmodifier;
  author: IAuthor;
  body: string;
  title: string;
}

export class Content implements IContent {
  public attachments: string[];
  public lastmodifier: ILastmodifier;
  public author: IAuthor;
  public body: string;
  public title: string;
  constructor(data: any = {}) {
    this.attachments = data.attachments || [];
    this.lastmodifier = data.lastmodifier || new LastModifier();
    this.author = data.author || new Author();
    this.body = data.body || '';
    this.title = data.title || '';
  }
}
export interface ILocation {
  rev: string;
  latitude: number;
  x: number;
  y: number;
  zoom: number;
  type: string;
  longitude: number;
}

export class Location implements ILocation {
  public rev: string;
  public latitude: number;
  public x: number;
  public y: number;
  public zoom: number;
  public type: string;
  public longitude: number;
  constructor(data: any = {}) {
    this.rev = data.rev || null;
    this.latitude = data.latitude || null;
    this.x = data.x || null;
    this.y = data.y || null;
    this.zoom = data.zoom || null;
    this.type = data.type || null;
    this.longitude = data.longitude || null;
  }
}
export interface IState {
  state: string;
  type: string;
}
export class State implements IState {
  public state: string;
  public type: string;
  constructor(data: any = {}) {
    this.state = data.state || '';
    this.type = data.type || 'IB.EdBundle.Document.State';
  }
}
export interface IPlan {
  dueDate: Date;
  type?: string;
}
export class Plan implements IPlan {
  public dueDate: Date;
  public type?: string;
  constructor(data: any = {}) {
    this.dueDate = data.dueDate || '';
    this.type = data.type || 'IB.EdBundle.Document.Plan';
  }
}
export interface IInformed {
  type: string;
  email: string;
}
export class Informed implements IInformed {
  public type: string;
  public email: string;
  constructor(data: any = {}) {
    this.type = data.type || 'IB.EdBundle.Document.Person';
    this.email = data.email || '';
  }
}
export interface IResponsible {
  type: string;
  email: string;
}

export class Responsible implements IResponsible {
  public type: string;
  public email: string;
  constructor(data: any = {}) {
    this.type = data.type || 'IB.EdBundle.Document.Person';
    this.email = data.email || '';
  }
}
export interface IConsulted {
  type: string;
  email: string;
}

export class Consulted implements IConsulted {
  public type: string;
  public email: string;
  constructor(data: any = {}) {
    this.type = data.type || 'IB.EdBundle.Document.Person';
    this.email = data.email || '';
  }
}
export interface IParticipants {
  informed: IInformed[];
  responsible?: IResponsible;
  consulted: IConsulted[];
  reporter?: IResponsible | IConsulted[];
  type: string;
}
export class Participants implements IParticipants {
  public informed: IInformed[];
  public responsible?: IResponsible;
  public consulted: IConsulted[];
  public type: string;
  constructor(data: any = {}) {
    this.informed = data.informed || [];
    this.responsible = data.responsible || '';
    this.consulted = data.consulted || [];
    this.type = data.type || 'IB.EdBundle.Document.Participants';
  }
}
export interface ITicket {
  attachments: Attachment[];
  groupId: string;
  project: string;
  dates: IDates;
  content: IContent;
  tags: any[];
  customFields?: ICustomFieldsValue[] | [];
  archived?: any;
  deleted?: any;
  database: string;
  location: ILocation;
  state: IState;
  couchDbId: string;
  _id: string;
  audits: string[];
  map: string;
  plan: IPlan;
  participants: IParticipants;
  comments: IComments[];
}

export interface ITicketList {
  hits: number;
  page: number;
  results: ITicket[];
}

export class Ticket implements ITicket {
  attachments: Attachment[];
  groupId: string;
  project: string;
  dates: IDates;
  content: IContent;
  tags: any[];
  archived?: any;
  database: string;
  location: ILocation;
  state: IState;
  couchDbId: string;
  _id: string;
  audits: string[];
  map: string;
  plan: IPlan;
  participants: IParticipants;
  customFields?: ICustomFieldsValue[] | [];
  comments: IComments[];

  constructor(data: any = {}) {
    this.attachments = data.attachments || {};
    this.groupId = data.groupId || '';
    this.project = data.project || '';
    this.dates = data.dates || new Dates();
    this.content = data.content || new Content();
    this.tags = data.tags || [];
    this.archived = data.archived || null;
    this.database = data.database || '';
    this.location = data.location || new Location();
    this.state = data.state || new State();
    this.couchDbId = data.couchDbId || '';
    this._id = data._id || '';
    this.audits = data.audits;
    this.map = data.map || '';
    this.plan = data.plan || new Plan();
    this.participants = data.participants || new Participants();
    this.customFields = data.customFields || [];
    this.comments = data.comments || [];
  }
}
export interface ITicketDetail {
  _id: string;
  _rev: string;
  archived: string | null;
  deleted: string | null;
  audits: string[];
  content: IContent;
  dates: IDates;
  location: ILocation;
  map: string;
  groupId: string;
  isProofMandatory: boolean;
  participants: IParticipants;
  doctrine_metadata: DoctrineMetadata;
  plan: IPlan;
  project: string;
  state: IState;
  operation: Operation[];
  comments: IComments[];
  tags: string[];
  customFields?: ICustomFieldsValue[] | [];
  type: string;
  _attachments: { [key: string]: IAttachment };
}

export interface IDoctrineMetadata {
  associations: string[];
  indexed: boolean;
}

export class DoctrineMetadata implements IDoctrineMetadata {
  public associations: string[];
  public indexed: boolean;
  constructor() {
    this.associations = ['project', 'map'];
    this.indexed = true;
  }
}
export interface Operation {
  changedProperties: string[];
  oldValues: Array<null | string>;
  newValues: Array<string[] | string>;
  author: string;
  time: string;
  summary: string;
  actionType: string;
  platform: IPlatform;
}

export class TicketDetail implements ITicketDetail {
  public _id: string;
  public _rev: string;
  public archived: string | null;
  public deleted: string | null;
  public audits: string[];
  public content: IContent;
  public dates: IDates;
  public location: ILocation;
  public map: string;
  public groupId: string;
  public participants: IParticipants;
  public doctrine_metadata: IDoctrineMetadata;
  public plan: IPlan;
  public project: string;
  public isProofMandatory: boolean;
  public state: IState;
  public operation: Operation[];
  public comments: IComments[];
  public tags: string[];
  public customFields?: ICustomFieldsValue[] | [];
  public type: string;
  public _attachments: { [key: string]: IAttachment };
  constructor(data: any = {}) {
    this._id = data._id || '';
    this._rev = data._rev || '';
    this.archived = data.archived || null;
    this.deleted = data.deleted || null;
    this.audits = data.audits || [];
    this.content = data.content || new Content();
    this.dates = data.dates || new Dates();
    this.location = data.location || new Location();
    this.map = data.map || '';
    this.groupId = data.groupId || '';
    this.participants = data.participants || new Participants();
    this.doctrine_metadata = data.doctrine_metadata || new DoctrineMetadata();
    this.plan = data.plan || new Plan();
    this.project = data.project || '';
    this.isProofMandatory = data.isProofMandatory || false;
    this.state = data.state || new State();
    this.operation = data.operation || [];
    this.comments = data.comments || [];
    this.customFields = data.customFields || [];
    this.tags = data.tags || [];
    this.type = data.type || 'IB.EdBundle.Document.Ticket';
    this._attachments = data._attachments || {};
  }
}

export class AddRole implements IResponsible {
  public type: string;
  public email: string;
  constructor(email: string = '') {
    this.type = 'IB.EdBundle.Document.Person';
    this.email = email;
  }
}

export interface IShowPagination {
  next: boolean;
  prev: boolean;
}
export class ShowPagination implements IShowPagination {
  public next: boolean;
  public prev: boolean;
  constructor(data: any = {}) {
    this.next = data.next || false;
    this.prev = data.prev || false;
  }
}

export interface ILightBoxData {
  index: number;
  open: boolean;
  data?: any;
}
export interface IPaginationData {
  totalTicket: number;
  pageNumber: number;
  index: number;
  totalPages: number;
  from: number;
  to: number;
  ticketList: Array<ITicket>;
  ticketListSize: number;
}

export class PaginationData implements IPaginationData {
  public totalTicket: number;
  public pageNumber: number;
  public index: number;
  public totalPages: number;
  public from: number;
  public to: number;
  public ticketList: Array<ITicket>;
  public ticketListSize: number;
  constructor(ticketList: ITicket[] = [], ticketListSize?, pageNumber?, ticketId?) {
    this.totalTicket = ticketList.length || 0;
    this.pageNumber = pageNumber || 0;
    this.index = ticketList.findIndex(ticket => ticket.couchDbId === ticketId) || 0;
    this.totalPages = Math.ceil(ticketListSize / 20) || 0;
    this.from = pageNumber * 20 || 0;
    this.to = pageNumber * 20 + 20 || 0;
    this.ticketList = ticketList.slice() || [];
    this.ticketListSize = ticketListSize || 0;
  }
}

export interface IShowHideRole {
  consulted: boolean;
  informed: boolean;
}
export class ShowHideRole implements IShowHideRole {
  public consulted: boolean;
  public informed: boolean;
  constructor(data: any = {}) {
    this.consulted = data.consulted || false;
    this.informed = data.informed || false;
  }
}
export interface IComments {
  attachments: string[];
  author: string;
  time: string;
  note: string;
  public: boolean;
  allowedUsers: string[] | null;
}

export class Comments implements IComments {
  public attachments: string[];
  public author: string;
  public time: string;
  public note: string;
  public public: boolean;
  public allowedUsers: string[] | null;
  constructor(data: any = {}) {
    this.attachments = data.attachments || [];
    this.author = data.author || '';
    this.time = data.time || '';
    this.note = data.note || '';
    this.public = data.public || true;
    this.allowedUsers = data.allowedUsers || '';
  }
}

export interface ITicketByID {
  ticketId: string;
  allTickets: boolean;
  sortby: string;
  sortOrder: string;
  includeFields: string[];
}
export class TicketByID implements ITicketByID {
  ticketId: string;
  allTickets: boolean;
  sortby: string;
  sortOrder: string;
  includeFields: string[];
  constructor(data: any = {}) {
    this.ticketId = data || '';
    this.allTickets = true;
    this.sortby = 'LASTMODIFIEDDATE';
    this.sortOrder = 'desc';
    this.includeFields = [];
  }
}

export type ChangeType = 'consulted' | 'informed' | 'responsible' | 'title' | 'dueDate' | 'description' | 'tags' | 'archive' | 'status' | 'isProofMandatory';

export type SuggestionType = 'title' | 'description';

export interface IGeoReferencePoints {
  xPixel: number;
  yPixel: number;
}

export interface IBulkEditObject {
  status: string;
  database: string;
  channelId: string;
  tags: null;
  time: string;
  operationType: string;
  roles: null;
  documentIds: string[];
  platform: IPlatform;
}

export class BulkEditObject implements IBulkEditObject {
  public status: string;
  public database: string;
  public channelId: string;
  public tags: null;
  public time: string;
  public operationType: string;
  public roles: any;
  public documentIds: string[];
  public platform: IPlatform;
  public actionOnRoles: string[];
  constructor(data: any = {}) {
    this.status = data.status || '';
    this.database = data.database || '';
    this.channelId = data.channelId || '';
    this.tags = data.tags || null;
    this.time = data.time || new Date().toLocaleTimeString('en-US', { hour12: false });
    this.operationType = data.operationType || 'add';
    this.roles = data.roles || null;
    this.documentIds = data.documentIds || [];
    this.platform = data.platform || new Platform();
    this.actionOnRoles = data.actionOnRoles || [];
  }
}

export type ITicketDupicate = {
  tags: boolean;
  title: boolean;
  dueDate: boolean;
  informed: boolean;
  consulted: boolean;
  responsible: boolean;
  description: boolean;
};

export class TicketDuplicate implements ITicketDupicate {
  tags: boolean;
  title: boolean;
  dueDate: boolean;
  informed: boolean;
  consulted: boolean;
  responsible: boolean;
  description: boolean;
  constructor(data: any = {}) {
    this.tags = data.tags || false;
    this.title = data.title || false;
    this.dueDate = data.dueDate || false;
    this.informed = data.informed || false;
    this.consulted = data.consulted || false;
    this.description = data.description || false;
    this.responsible = data.responsible || false;
  }
}

export type ISelectedStatus = {
  createdState: number;
  startedState: number;
  completedState: number;
};

export class SelectedStatus implements ISelectedStatus {
  createdState: number;
  startedState: number;
  completedState: number;
  constructor(data: any = {}) {
    this.createdState = data.createdState || 0;
    this.startedState = data.startedState || 0;
    this.completedState = data.completedState || 0;
  }
}
