import moment from 'moment';

import { apiUrl } from '../../utils/api';
import { GeoMap } from '../../models/map';
import { IContract } from '../../models/contract';
import { IProjectFilter } from '../../models/filter';
import { saveBulk } from '../../utils/couchdb-elasticHelper';
import { IProject, ProjectModel } from '../../models/project.model';
import { generateFullName, getProjectId, storeMailstoAppstate } from '../../utils/utils';
import { convertDate, generateDateTime } from '../../utils/dateHelper';
import { IOperationModel, OperationTimeline } from '../../models/timeline';
import { sendGetRequest, sendPostRequest, sendPutRequest } from '../../utils/requestController';

import infoRed from './../../images/info-red.svg';
//These keys are sent from the server and are not used in the code apart from reading once
interface ProjectFilterKeys {
  favourite: boolean;
  isEditable: boolean;
  roles: any;
  projectName: string;
  contract: string;
  endDate: string;
  geomap: boolean;
  thumbImage: string;
}
export type ProjectType = ProjectModel & Partial<ProjectFilterKeys>;

type ContractType<T = IContract> = {
  [P: string]: T;
};

type Projects<T = ProjectType> = {
  [P: string]: T;
};

/** Get list*/
const getProjectList = async (email: string) => {
  const url = `${apiUrl.license_server_v2}/user/${email}/projects`;
  return new Promise<{ projects: any; contracts: any }>(resolve => {
    sendGetRequest(url).then(data => {
      resolve(data);
    });
  });
};

const getAdminList = async (dbName: string, projectId: string) => {
  const url = `${apiUrl.v2api}projects/${dbName}/${projectId}/getAdminInfo`;
  return new Promise(resolve => {
    sendGetRequest(url).then(data => {
      resolve(data);
    });
  });
};

const updateContractLicense = async (licenseName: string, contractId: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const contractData = await getContract(contractId);
      contractData.licenseType = licenseName;
      contractData.archived = null;
      contractData.contractActive = true;
      const res = await updateContract(contractId, contractData);
      if (res) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
const buyStorageLicense = (contractId: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const contractData = await getContract(contractId);
      contractData.licenseType = 'storage';
      contractData.archived = null;
      contractData.contractActive = true;
      const res = await updateContract(contractId, contractData);
      if (res) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
const deleteContract = (contractId: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const contractData = await getContract(contractId);
      contractData.deleted = new Date().toISOString();
      const res = await updateContract(contractId, contractData);
      if (res) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

const getNpsStatus = async () => {
  const url = `${apiUrl.v2api}user/isShowNPS`;
  return new Promise<{ isShowNPS: string }>(resolve => {
    sendGetRequest(url).then(data => {
      resolve(data);
    });
  });
};

export const formatProjects = (data: { projects: Projects; contracts: ContractType<IContract> }, appState) => {
  let key: string,
    proj_acc_to_contract = {},
    projectObj;
  const contract_info = { admin_contract: {}, all_contracts: {} };
  const projects: ProjectModel[] = [];

  appState.set('allDb', data.projects || {}, 'projects');
  appState.set('allContr', data.contracts || {}, 'contracts');
  const userToken = appState.get('accessToken', 'user');
  return new Promise<any>(resolve => {
    for (key in data.projects) {
      if (data.projects.hasOwnProperty(key)) {
        projectObj = new ProjectModel();
        projectObj.couchDbId = data.projects[key].couchDbId;
        projectObj.name = data.projects[key].projectName;
        projectObj.internalPurchaseNumber = data.projects[key].internalPurchaseNumber;
        projectObj.dbName = key;
        projectObj.location = data.projects[key].location;
        projectObj.contractId = data.projects[key].contract;
        // @ts-ignore
        if (!data.projects[key]?.isOnPremise) {
          const parsedUrl = new URL(data.projects[key].thumbImage);
          const pathname = parsedUrl.pathname;
          projectObj.projectImage = pathname + `?access_token=${userToken}`;
        } else {
          projectObj.projectImage = data.projects[key].thumbImage + `?access_token=${userToken}`;
        }
        projectObj.archived = data.projects[key].archived;
        projectObj.roles = data.projects[key].roles || {};
        projectObj.endDate = data.projects[key].endDate;
        projectObj.invoicedDate = data.projects[key].invoicedDate;
        projectObj.settings.modules.geomap = data.projects[key].geomap;
        projectObj.referenceName = data.projects[key].referenceName;
        projectObj.archivedContract = data.contracts[projectObj.contractId].archived;
        projectObj.isGlacier = data.projects[key].isGlacier;

        if (data.contracts[data.projects[key].contract!].admin) {
          projectObj.roles.administrator = true;
          contract_info.admin_contract[data.projects[key].contract] = {
            admin: true,
          };
        }
        if (data.contracts[data.projects[key].contract].manager) {
          projectObj.roles.manager = true;
        }
        if (!proj_acc_to_contract[data.projects[key].contract!]) {
          proj_acc_to_contract[data.projects[key].contract] = [];
        }
        proj_acc_to_contract[data.projects[key].contract].push(key);
        projects.push(projectObj);
      }
    }

    contract_info.all_contracts = data.contracts;
    //for storing projects list in contract
    for (key in contract_info.admin_contract) {
      if (contract_info.admin_contract.hasOwnProperty(key)) {
        contract_info.admin_contract[key].projects = [];
        contract_info.admin_contract[key].projects = proj_acc_to_contract[key];
      }
    }
    resolve(filterProjectsByRole(projects, appState));
  });
};

const filterProjectsByRole = (projectsList: ProjectType[], appState) => {
  const USER = appState.get('id', 'user');
  let isFavourite: boolean = false,
    sAdmin: boolean = false,
    showProject = true;
  const active: ProjectType[] = [],
    archive: ProjectType[] = [],
    favourite: ProjectType[] = [];
  let flag, contractRole;

  const userContracts = appState.get('allContr', 'contracts') || {};
  sAdmin = appState.get('sAdmin', 'user') || false;

  flag = localStorage.getItem('ed_favorite') || '{}';
  flag = JSON.parse(flag);

  if (flag[USER]) flag = flag[USER].favorite;
  else flag = [];

  projectsList.forEach(project => {
    showProject = true;

    //check if project is in favourites of LocalStorage
    if (flag?.length > 0) {
      isFavourite = flag.includes(project.dbName);
    }

    contractRole = project.contractId ? userContracts[project.contractId] || {} : {};
    if (contractRole.admin || project.roles.accountable) {
      project.isEditable = true;
    }

    //if project archived date is not null
    //only dutchview, admin, manager, accountable and support is allowed to see the project
    if (project.archived) {
      if (sAdmin || contractRole.admin || contractRole.manager || project.roles.accountable || project.roles.support) {
        if (isFavourite) {
          project.favourite = true;
        }
        archive.push(project);
      } else {
        showProject = false;
      }
    } else if (isFavourite) {
      /**
       * if project is availabe in favourites and user role is eligible to view project
       * push to the respective array
       */
      if (showProject) {
        project.favourite = true;
        favourite.push(project);
      }
    } else {
      if (showProject) {
        active.push(project);
      }
    }
  });

  return [...favourite, ...active, ...archive];
};

/**
 * Filter the contracts if user is Reporter, RCI, Support
 * Remove the ones that have no projects visible
 * @param contracts
 * @param projects
 * @param appState
 * @returns
 */
export const filterContractsByRole = (contracts: ContractType, projects: Projects, appState) => {
  const filteredContracts: Object = {};
  const sAdmin = appState.get('sAdmin', 'user') || false;

  for (let contract in contracts) {
    if (sAdmin || contracts[contract].admin || contracts[contract].manager) {
      filteredContracts[contract] = contracts[contract];
      continue;
    }
    for (let project in projects) {
      /**
       * If user is accountable of that project or
       * If Reporter, RCI or Support then check if project is archived, if yes don't add contract from iteration
       */
      if (projects[project].roles.accountable || !projects[project].archived || (projects[project].roles.support && projects[project].contract === contract)) {
        filteredContracts[contract] = contracts[contract];
        break;
      }
    }
  }
  return { ...filteredContracts };
};

export const splitAndSortProjects = async (list: ProjectType[], sortOptions: any = {}, isSortNeeded: boolean = true) => {
  return new Promise((resolve, reject) => {
    const active: ProjectModel[] = [];
    const archive: ProjectModel[] = [];
    const activeFavourites: ProjectModel[] = [];
    const archiveFavourites: ProjectModel[] = [];

    for (const project of list) {
      if (project.archived) {
        if (project.favourite) {
          archiveFavourites.push(project);
        } else {
          archive.push(project);
        }
      } else {
        if (project.favourite) {
          activeFavourites.push(project);
        } else {
          active.push(project);
        }
      }
    }

    const sortedList = {
      active: [...activeFavourites, ...active],
      archive: [...archiveFavourites, ...archive],
    };

    if (sortedList) {
      if (isSortNeeded) {
        if (sortOptions.active === 'Project name') {
          sortedList.active.sort((a: ProjectModel, b: ProjectModel) => new Intl.Collator().compare(a.name.toLowerCase(), b.name.toLowerCase()));
          sortedList.archive.sort((a: ProjectModel, b: ProjectModel) => new Intl.Collator().compare(a.name.toLowerCase(), b.name.toLowerCase()));
        } else if (sortOptions.active === 'End date') {
          //@ts-ignore
          sortedList.active.sort((a: ProjectModel, b: ProjectModel) => {
            //@ts-ignore
            return new Date(b.endDate) - new Date(a.endDate);
          });
          sortedList.archive.sort((a: ProjectModel, b: ProjectModel) => {
            //@ts-ignore
            return new Date(b.endDate) - new Date(a.endDate);
          });
        }

        if (sortOptions.direction === 'desc') {
          sortedList.active.reverse();
          sortedList.archive.reverse();
        }
      }
      resolve({ ...sortedList });
    } else {
      reject('Error processing projects.');
    }
  });
};

/**
 *
 * @param filter
 * @param projects
 * @param appState
 * @returns
 */
const filterProjects = (filter: IProjectFilter, projects: any[], appState) => {
  const { contract, filter: filters, searchByName } = filter;
  const fav = JSON.parse(localStorage.getItem('ed_favorite') || '{}')[appState.get('id', 'user')] || { favorite: [] };
  return projects.filter(project => {
    if (contract && project.contractId !== contract) return false;
    if (filters.includes('archived') && project.archived === null) return false;
    if (!filters.includes('archived') && project.archived !== null) return false;
    if (filters.includes('starred') && !fav?.favorite?.includes(project.dbName)) return false;

    const normalizeString = (str: string) =>
      str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

    if (searchByName) {
      const { name, referenceName, location } = project;
      const searchTerm = normalizeString(searchByName);

      if (
        !normalizeString(name).includes(searchTerm) &&
        !normalizeString(referenceName || '').includes(searchTerm) &&
        !(location && typeof location === 'string' && normalizeString(location).includes(searchTerm))
      ) {
        return false;
      }
    }

    return true;
  });
};
const getFilteredProjects = (email: string, projects, sortOptions, filter) => {
  let url = `${apiUrl.license_server_v2}/user/${email}/projects/searchProjectByName?searchBy=${encodeURIComponent(filter.searchByName)}&sortOrder=${sortOptions.direction}&sortBy=${'name'}`;
  if (filter.contract) {
    url += `&contractId=${filter.contract}`;
  }
  if (filter.filter.includes('archived')) {
    url += `&archived=true`;
  }

  return new Promise<any>((resolve, reject) => {
    sendGetRequest(url)
      .then(async result => {
        if (result && result.length > 0) {
          const data = projects.filter(project => result.includes(project.dbName));
          const filterData = await splitAndSortProjects(data, sortOptions, true);
          resolve(filterData);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
const getProjectImage = (databaseId: string, imageType: string, appState, projectId?: string) => {
  return `${apiUrl.secureData}${databaseId}/${projectId}/${imageType}?access_token=${appState.get('accessToken', 'user')}`;
};

const addProject = (contractId: string, project: IProject) => {
  const url = `${apiUrl.v2api}projects?contractId=${contractId}`;
  return new Promise<any>((resolve, reject) => {
    sendPostRequest(url, project)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const updateProject = (project: IProject) => {
  const contractId = project.contractId;
  const projectId = project._id;
  const url = `${apiUrl.v2api}projects/${contractId}/${projectId}`;
  return new Promise<{ projects: any; contracts: any }>(resolve => {
    sendPutRequest(url, project).then(data => {
      resolve(data);
    });
  });
};
const bulkUpdateService = (data: any) => {
  const url = `api/v1/bulk/project`;
  return new Promise(resolve => {
    sendPostRequest(url, data).then(res => {
      resolve(res);
    });
  });
};

const getUniqueUserList = (project: string) => {
  const url = `${apiUrl.v2api}projects/${project}/getBillableUser`;
  return sendGetRequest(url);
};

const showSnackBar = (title: string, snackbar) => {
  snackbar({
    title,
    hideCancel: true,
    open: true,
  });
};

const handleDeleteContractClick = (confirmDialog, lang, snackbar, contractId: string) => {
  return new Promise<any>(resolve => {
    confirmDialog({
      catchOnCancel: false,
      title: lang.m_lbl_deleting_contract,
      cancelLabel: lang.m_btn_cancel,
      successLabel: lang.m_lbl_delete_contract,
      description: [lang.m_lbl_delete_contr_hint_1, lang.m_lbl_delete_contr_hint_2],
      maxWidthProp: 'sm',
      icon: infoRed,
    })
      .then(async () => {
        const response = await deleteContract(contractId);
        if (response) {
          resolve(true);
          showSnackBar(lang.m_lbl_delete_contr_success, snackbar);
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

const handleViewLicenseClick = (confirmDialog, lang, snackbar, contractId: string) => {
  return new Promise<any>(resolve => {
    confirmDialog({
      catchOnCancel: true,
      title: lang.view_only.view_only_license_label,
      cancelLabel: lang.m_btn_cancel,
      successLabel: lang.m_lbl_get_license,
      description: [lang.view_only.license_buy_hint_1, lang.view_only.license_buy_hint_2],
      maxWidthProp: 'sm',
      showIcon: false,
    })
      .then(async () => {
        const response = await buyStorageLicense(contractId);
        if (response) {
          resolve(true);
          showSnackBar(lang.view_only_license_buy_success, snackbar);
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};
const getIsLastActiveProject = (databaseId: string) => {
  const url = `${apiUrl.v2api}projects/${databaseId}/getActiveProjectCount`;
  return new Promise<any>((resolve, reject) => {
    sendGetRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(() => {
        reject(false);
      });
  });
};
const getContract = async (contractId: string) => {
  const url = `${apiUrl.v2api}admin/contracts/${contractId}/getContract`;

  return new Promise<any>(resolve => {
    sendGetRequest(url).then(data => {
      resolve(data);
    });
  });
};
const updateContract = async (contractId: string, contractData: any) => {
  const url = `${apiUrl.v2api}admin/contracts/${contractId}/updateContract`;

  return new Promise<any>(resolve => {
    sendPutRequest(url, contractData).then(data => {
      resolve(data);
    });
  });
};
const checkAndCreateGeoDoc = async (project: IProject, existingProject: IProject, databaseId: string, appState) => {
  if (project?.settings?.modules?.geomap !== existingProject.settings?.modules?.geomap && project.settings?.modules?.geomap) {
    //check if geo doc exists
    const url = `${apiUrl.secureData}${databaseId}/EDGeomapMapID?dummy= ${new Date().getTime()}`;
    await sendGetRequest(url)
      .then(() => {
        return;
      })
      .catch(() => {
        const doc = [];
        const geoMapData = new GeoMap({}, appState);
        doc.push(geoMapData);
        saveBulk(doc, databaseId);
      });
  }
};

const getProjectById = (contractId: string, projectId: string) => {
  const url = `${apiUrl.v2api}projects/${contractId}/${projectId}`;
  return new Promise<{ project: ProjectModel }>((resolve, reject) => {
    sendGetRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
const restoreProjectById = (databaseId: string, body) => {
  const url = `${apiUrl.v1api}sysOps/restoreProject/${databaseId}`;
  return new Promise<{ project: ProjectModel }>((resolve, reject) => {
    sendPostRequest(url, body)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getUserListByDatabaseIdArray = (projectIdList: string[], appState) => {
  const url = `${apiUrl.v2api}users/getUsersInfoSecure`;
  let body: any = { projects: projectIdList };
  return new Promise<any>((resolve, reject) => {
    sendPostRequest(url, body)
      .then(data => {
        const userList = storeMailstoAppstate(data, appState);
        resolve(userList);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const copyProjectModules = (copyModules: any) => {
  const url = `${apiUrl.v2api}projects/copyModules`;
  return new Promise<any>((resolve, reject) => {
    sendPostRequest(url, copyModules)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const uploadAttachment = (url, formData) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return new Promise((resolve, reject) => {
    sendPutRequest(url, formData, config).then(
      result => {
        resolve(result);
      },
      error => {
        reject(error);
      },
    );
  });
};
const generateProjectTimelineView = (operations, lang_keys, appState, personList) => {
  let timeLineObj: IOperationModel[] = [],
    tempTimeline_obj: IOperationModel,
    author,
    date;

  const generatePersonMessage = (value, type, property) => {
    let message = '';
    if (type === 'revoke') {
      message = lang_keys.m_txt_timeline_revokerole_web.replace('__PERSON_2__', generateFullName(value, appState, lang_keys, personList));
      message = message.replace('__ROLE__', property);
    } else {
      message = lang_keys.m_txt_timeline_assignrole_web.replace('__PERSON_2__', generateFullName(value, appState, lang_keys, personList));
      message = message.replace('__ROLE__', property);
    }
    return message;
  };

  const generateInformedConsultedSupportOperation = (operation, property, index) => {
    let type_lang_key;
    if (property === 'consulted') {
      type_lang_key = lang_keys.m_lbl_cons;
    } else if (property === 'informed') {
      type_lang_key = lang_keys.m_lbl_info;
    } else if (property === 'support') {
      type_lang_key = lang_keys.m_lbl_sup;
    }

    if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
      operation.newValues[index].forEach(value => {
        tempTimeline_obj = new OperationTimeline(author, date, property);
        tempTimeline_obj.message = generatePersonMessage(value, 'assign', type_lang_key);
        timeLineObj.push(tempTimeline_obj);
      });
    } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
      // Removing all consulted role
      operation.oldValues[index].forEach(value => {
        tempTimeline_obj = new OperationTimeline(author, date, property);
        tempTimeline_obj.message = generatePersonMessage(value, 'revoke', type_lang_key);
        timeLineObj.push(tempTimeline_obj);
      });
    } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
      operation.oldValues[index] &&
        operation.oldValues[index].forEach(value => {
          if (!operation.newValues[index].includes(value)) {
            tempTimeline_obj = new OperationTimeline(author, date, property);
            tempTimeline_obj.message = generatePersonMessage(value, 'revoke', type_lang_key);
            timeLineObj.push(tempTimeline_obj);
          }
        });
      operation.newValues[index] &&
        operation.newValues[index].forEach(value => {
          if (!operation.oldValues[index].includes(value)) {
            tempTimeline_obj = new OperationTimeline(author, date, property);
            tempTimeline_obj.message = generatePersonMessage(value, 'assign', type_lang_key);
            timeLineObj.push(tempTimeline_obj);
          }
        });
    }
  };

  if (operations && operations.length > 0) {
    operations.forEach(operation => {
      if (operation.changedProperties && operation.changedProperties.length > 0 && operation.author && operation.time) {
        author = generateFullName(operation.author, appState, lang_keys, personList) || lang_keys.m_lbl_acc;
        date = operation.time ? generateDateTime(operation.time, appState) : lang_keys.m_lbl_na;
        operation.changedProperties.forEach((property, index) => {
          switch (property) {
            case 'referenceName':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.add_ref_name.replace('__NAME__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
                return;
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.edit_ref_name.replace('__NAME__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.remove_ref_name.replace('__NAME__', operation.oldValues[index]);
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'internalPurchaseNumber':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.add_internal_purchase_num.replace('__NUMBER__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
                return;
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.edit_internal_purchase_num.replace('__NUMBER__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.remove_internal_purchase_num.replace('__NUMBER__', operation.oldValues[index]);
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'name':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.add_name.replace('__NAME__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.edit_name.replace('__NAME__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = `removed project name ${operation.oldValues[index]}`;
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'archived':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (author === 'scheduler@edcontrols.com') {
                  tempTimeline_obj.message = lang_keys.m_lbl_archive_scheduler;
                  tempTimeline_obj.author = 'Scheduler (Ed Controls)';
                } else {
                  tempTimeline_obj.message = lang_keys.project_timeline.archive;
                }
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.dearchive;
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'location':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.add_location.replace('__LOCATION__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.edit_location.replace('__LOCATION__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = `deleted project location ${operation.oldValues[index]}`;
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'consulted':
            case 'informed':
            case 'support':
              generateInformedConsultedSupportOperation(operation, property, index);
              break;
            case 'accountable':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = generatePersonMessage(operation.newValues[index], 'assign', lang_keys.m_lbl_acc);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = generatePersonMessage(operation.oldValues[index], 'revoke', lang_keys.m_lbl_acc);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                // Revoke old resp
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = generatePersonMessage(operation.oldValues[index], 'revoke', lang_keys.m_lbl_acc);
                timeLineObj.push(tempTimeline_obj); // Push 1st object for revoke

                // Assign new resp
                tempTimeline_obj = new OperationTimeline(author, date, property); // create a new object for assign
                tempTimeline_obj.message = generatePersonMessage(operation.newValues[index], 'assign', lang_keys.m_lbl_acc);
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'startDate':
            case 'endDate':
              if (!operation.oldValues[index] && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (property === 'startDate') {
                  tempTimeline_obj.message = lang_keys.project_timeline.add_startdate.replace('__DATE__', convertDate(operation.newValues[index]));
                } else {
                  const newDate = operation.newValues[index].substring(0, 10) + 'T00:00:00.000Z';
                  tempTimeline_obj.message = lang_keys.project_timeline.add_enddate.replace('__DATE__', convertDate(newDate));
                }
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (property === 'startDate') {
                  tempTimeline_obj.message = lang_keys.project_timeline.edit_startdate
                    .replace('__NEWDATE__', convertDate(operation.newValues[index]))
                    .replace('__OLDDATE__', convertDate(operation.oldValues[index]));
                } else {
                  const newDate = operation.newValues[index].substring(0, 10) + 'T00:00:00.000Z';
                  const oldDate = operation.oldValues[index].substring(0, 10) + 'T00:00:00.000Z';
                  tempTimeline_obj.message = lang_keys.project_timeline.edit_enddate.replace('__NEWDATE__', convertDate(newDate)).replace('__OLDDATE__', convertDate(oldDate));
                }
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && !operation.newValues[index]) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (property === 'startDate') {
                  tempTimeline_obj.message = lang_keys.project_timeline.remove_startdate.replace('__DATE__', convertDate(operation.oldValues[index]));
                }else{
                  tempTimeline_obj.message = lang_keys.project_timeline.remove_enddate.replace('__DATE__', convertDate(operation.oldValues[index]));
                }
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'geomap':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'false') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.enable_geomap;
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && (!operation.newValues[index] || operation.newValues[index] === 'null' || operation.newValues[index] === 'false')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.disable_geomap;
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'invoicedDate':
              if (!operation.oldValues[index] && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (property === 'invoicedDate') {
                  tempTimeline_obj.message = lang_keys.project_timeline.add_invoicedate.replace('__DATE__', convertDate(operation.newValues[index]));
                }
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (property === 'invoicedDate') {
                  tempTimeline_obj.message = lang_keys.project_timeline.edit_invoicedate
                    .replace('__NEWDATE__', convertDate(operation.newValues[index]))
                    .replace('__OLDDATE__', convertDate(operation.oldValues[index]));
                }
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'isProofMandatory':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'false') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.enable_ticket_proof;
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && (!operation.newValues[index] || operation.newValues[index] === 'null' || operation.newValues[index] === 'false')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.disable_ticket_proof;
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'attachment':
              if (!operation.oldValues[index] && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (operation.newValues[index].includes('logo')) {
                  tempTimeline_obj.message = lang_keys.project_timeline.add_logo;
                } else {
                  tempTimeline_obj.message = lang_keys.project_timeline.add_project_image;
                }
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (operation.newValues[index].includes('logo')) {
                  tempTimeline_obj.message = lang_keys.project_timeline.edit_logo;
                } else {
                  tempTimeline_obj.message = lang_keys.project_timeline.edit_project_image;
                }
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'contract':
              if (operation.oldValues[index] && operation.newValues[index] && operation.oldValues.length == operation.newValues.length) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.m_lbl_from_contract
                  .replace('__PROJECT__', '')
                  .replace('__OLD__', operation.oldValues[index])
                  .replace('__NEW__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'label':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.custom_fields.add_project_label.replace('__label__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
                return;
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.custom_fields.edit_project_label.replace('__NEW__', operation.newValues[index]).replace('__OLD__', operation.oldValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.custom_fields.remove_project_label.replace('__OLD__', operation.oldValues[index]);
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            default:
              if (property.startsWith('type:')) {
                let label = '';
                const pattern = /type:(.*)/;
                const match = property.match(pattern);
                if (match) {
                  label = match[1].trim();
                }

                const customTypes = {
                  freetext: lang_keys.m_lbl_fretxt,
                  numeric: lang_keys.m_lbl_numeric,
                  date: lang_keys.m_lbl_date,
                  singleSelectMultipleChoice: lang_keys.m_lbl_sing,
                  multiSelectMultipleChoice: lang_keys.m_lbl_mult,
                };

                if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                  tempTimeline_obj = new OperationTimeline(author, date, property);
                  tempTimeline_obj.message = lang_keys.custom_fields.add_project_type.replace('__label__', label).replace('__type__', customTypes[operation.newValues[index]]);
                  timeLineObj.push(tempTimeline_obj);
                  return;
                } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                  tempTimeline_obj = new OperationTimeline(author, date, property);
                  tempTimeline_obj.message = lang_keys.custom_fields.edit_project_type.replace('__label__', label).replace('__value__', customTypes[operation.newValues[index]]);
                  timeLineObj.push(tempTimeline_obj);
                }
              }
              break;
          }
        });
      }
    });
  }
  return timeLineObj.reverse();
};

export const getProjectDetails = async (database, couchDbId, appState) => {
  let projDetails;
  if (couchDbId) {
    const link = apiUrl.v2api + 'projects/' + database + '/' + couchDbId;
    projDetails = await sendGetRequest(link);
  } else {
    projDetails = await getProjectId(database);
  }
  const project: any = {};
  if (projDetails) {
    const data = projDetails;
    project.participants = data.participants;
    appState.set('allDb.active.participants', project.participants || {}, 'projects');
    appState.set('projectLocation', data.location || {}, 'projects');
    appState.set('allDb.active.metaData', data.metaData || {}, 'projects');
    appState.set('allDb.active.customFields', data.customFields || [], 'projects');
    appState.set('allDb.active.isProofMandatory', data.isProofMandatory || false, 'projects');
  }
  if (!couchDbId) {
    // Set mandatory fields for the project
    const projectId = projDetails.couchDbId || projDetails._id;
    const geoMapSetting = projDetails.settings?.modules?.geomap || false;

    appState.set('active', database, 'projects');
    appState.set('projectLocation', projDetails.location, 'projects');
    appState.set('allDb.active.id', projectId, 'projects');
    appState.set('allDb.active.participants', projDetails.participants || {}, 'projects');
    appState.set('allDb.active.geoMap', geoMapSetting, 'projects');

    return projDetails;
  }
};

const checkIsShowTrailProject = () => {
  const url = `${apiUrl.v2api}user/isFreeTrail`;
  return new Promise((resolve, reject) => {
    sendGetRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
const checkIsDemoProject = (project, appState) => {
  const allContract = appState.get('allContr', 'contracts');
  const getContract = allContract[project.contractId];
  if (getContract.isDemoContract !== undefined && project.archived === null && project.endDate !== null && getContract.isDemoContract === true) {
    const getCurrentDate = new Date().toISOString().substring(0, 10);
    const getEndDate = new Date(project.endDate).toISOString().substring(0, 10);
    const endDate = moment(getEndDate, 'YYYY/MM/DD');
    const currentDate = moment(getCurrentDate, 'YYYY/MM/DD');
    const remainingDays = endDate.diff(currentDate, 'days');
    return { freeTrial: true, remainingDays };
  }
  return { freeTrial: false };
};
export {
  getProjectList,
  filterProjects,
  addProject,
  updateProject,
  getProjectImage,
  getUserListByDatabaseIdArray,
  getProjectById,
  copyProjectModules,
  restoreProjectById,
  handleViewLicenseClick,
  handleDeleteContractClick,
  getIsLastActiveProject,
  getFilteredProjects,
  updateContractLicense,
  // deleteContract,
  generateProjectTimelineView,
  checkIsShowTrailProject,
  uploadAttachment,
  getNpsStatus,
  checkAndCreateGeoDoc,
  checkIsDemoProject,
  getUniqueUserList,
  getContract,
  updateContract,
  getAdminList,
  bulkUpdateService,
};
