import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import './SnackbarDialog.scss';

export interface SnackbarOptions {
  title: string | React.ReactNode;
  hideCancel?: boolean;
  open?: boolean;
  severity?: 'info' | 'success' | 'warning' | 'error' | undefined;
  customClass?: string;
  autoHideDuration?: number | null;
  extraBtnText?: number | null;
  isShowExtraBtn?: boolean;
  horizontalAlignment?: 'right' | 'left' | 'center';
}

interface SnackbarDialogProps extends SnackbarOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: (type?) => void;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const SnackbarDialog: React.FC<SnackbarDialogProps> = ({
  open,
  title,
  onClose,
  severity,
  customClass,
  isShowExtraBtn = false,
  extraBtnText,
  autoHideDuration,
  horizontalAlignment = 'right',
}) => {
  const _handleCloseClick = (type?) => {
    onClose(type);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: horizontalAlignment }}
        autoHideDuration={autoHideDuration ? autoHideDuration : 6000}
        open={open}
        onClose={() => _handleCloseClick()}
        className={`snackbardialog ${customClass ? customClass : ''}`}>
        <Alert severity={severity ? severity : 'info'} onClose={() => _handleCloseClick()}>
          {title}
          {isShowExtraBtn ? (
            <button className="btn btn--cancel" onClick={() => _handleCloseClick(isShowExtraBtn)}>
              {extraBtnText}
            </button>
          ) : (
            ''
          )}
        </Alert>
      </Snackbar>
    </>
  );
};
