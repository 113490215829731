import { AuditFilter, FileFilter, IAuditFilter, IFileFilter, IReporterFilter, ITemplateFilter, ITicketFilter, TemplateFilter, TicketFilter } from '../models/filter';
import { getFavoritesFromStorage, setFavorites } from './favoritesHelper';

export type EDFilters = ITicketFilter | IAuditFilter | ITemplateFilter | IFileFilter | IReporterFilter;

const sortFavoriteKey = {
  tickets: 'ticketFavorite',
  audits: 'auditFavorite',
  library: 'libraryFavorite',
  template: 'templateFavorite',
  print: 'printFavorite',
};
/**
 * Saves the applied filters for the specified module and current user to the application state.
 * @param {EDFilters} filters - The filters to be saved.
 * @param {('template' | 'tickets' | 'audits' | 'library' | 'reporter')} module - The module for which filters are being saved.
 * @param {AppState} appState - The application state object.
 * @author gaurav.rao
 */
export const saveUserAppliedFilters = (filters: EDFilters, module: 'template' | 'tickets' | 'audits' | 'library' | 'reporter', appState) => {
  if (!appState.get('switch', 'user')) {
    const user = appState.get('id', 'user');
    let userFilters: EDFilters = appState.get('users', 'filters') || {};
    userFilters[user] = { ...userFilters[user], [module]: filters };
    userFilters[user][module].projects = appState.get('projectIds', 'projects') || [];
    appState.set('users', userFilters, 'filters');
    const db = appState.get('active', 'projects');
    const starredData = getFavoritesFromStorage(user, db, module);
    setFavorites('sort', { sortOrder: filters?.sortOrder, sortby: filters?.sortby }, '', sortFavoriteKey[module], starredData, db, user);
  }
};

/**
 * Retrieves the applied filters for the specified module and current user from the application state.
 * @param {('template' | 'tickets' | 'audits' | 'library' | 'reporter')} module - The module for which filters are being retrieved.
 * @param {AppState} appState - The application state object.
 * @returns {object | undefined} - The applied filters for the specified module and current user, or undefined if user switch is enabled or no filters are found.
 * @author gaurav.rao
 */
export const getUserAppliedFilters = (module: 'template' | 'tickets' | 'audits' | 'library' | 'reporter', appState) => {
  if (!appState.get('switch', 'user')) {
    const user = appState.get('id', 'user');
    const userFilters = appState.get('users', 'filters');
    if (!userFilters) return;
    const db = appState.get('active', 'projects');
    const starredData = getFavoritesFromStorage(user, db, module);
    if (module !== 'reporter') {
      const sortData = starredData[sortFavoriteKey[module]].sort;
      const data = userFilters && userFilters[user] && userFilters[user][module] ? userFilters[user][module] : (userFilters[user] = { [module]: {} });
      let filter = {};
      switch (module) {
        case 'tickets':
          filter = new TicketFilter(data);
          break;
        case 'audits':
          filter = new AuditFilter(data);
          break;
        case 'library':
          filter = new FileFilter(data);
          break;
        case 'template':
          filter = new TemplateFilter(data);
          break;
      }
      if (sortData && sortData.sortby) {
        userFilters[user][module] = { ...filter, sortby: sortData.sortby, sortOrder: sortData.sortOrder };
      } else {
        userFilters[user][module] = { ...filter };
      }
    }
    return userFilters[user][module];
  }
};

/**
 * Clears saved filters for the current user in the application state.
 * @param {AppState} appState - The application state object.
 * @author gaurav.rao
 */
export const clearSavedFilters = appState => {
  // Check if the user switch is not enabled
  if (!appState.get('switch', 'user')) {
    const user = appState.get('id', 'user');
    // Get the user filters from the application state, defaulting to an empty object if not present
    let userFilters = appState.get('users', 'filters') || {};
    // Update the user filters to only retain the 'project' filter
    userFilters[user] = { project: userFilters[user].project };
    appState.set('users', userFilters, 'filters');
  }
};

/**
 * Checks if any filters are applied based on the provided filter object.
 * @param {object} filterObj - The filter object to check.
 * @param {string[]} [ignoreList=[]] - Optional list of keys to ignore when checking for applied filters.
 * @returns {boolean} - True if any filters are applied, otherwise false.
 * @author gaurav.rao
 */
export const isFilterApplied = (filterObj, ignoreList = []) => {
  for (const key in filterObj) {
    if (
      key !== 'aggs_type' &&
      key !== 'aggs_value' &&
      key !== 'view' &&
      key !== 'sortOrder' &&
      key !== 'sortby' &&
      key !== 'projects' &&
      key !== 'dummy' &&
      key !== 'activeDatabase' &&
      key !== 'path' &&
      key !== 'filterRole' &&
      !ignoreList.includes(key)
    ) {
      if (Array.isArray(filterObj[key])) {
        if (filterObj[key].length > 0) {
          return true;
        }
      } else if (typeof filterObj[key] === 'boolean') {
        if (filterObj[key] === true) {
          return true;
        }
      } else if (filterObj[key] !== '' && filterObj[key] !== null) {
        return true;
      }
    }
  }
  return false;
};
