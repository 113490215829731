import React from 'react';

import { AppStateContext } from '../..';
import { useSnackbar } from '../../components/Dialogs/SnackbarDialog/SnackbarService';

import './YearReview.scss';

import closeIcon from '../../images/close-white.svg';

type YearReviewProps = {
  onClose?: () => void;
  onLoginShown: boolean;
};

const YearReview = ({ onClose, onLoginShown }: YearReviewProps) => {
  const { language: lang, appState } = React.useContext(AppStateContext);
  const accessToken = appState.get('accessToken', 'user') || '';
  const snackbar = useSnackbar();

  const isDev = window.location.hostname.includes('dev.edcontrols');
  const baseUrl = isDev ? 'https://dev-yearinreview.edcontrols.com/edcontrols' : 'https://yearinreview.edcontrols.com/edcontrols';
  const link = `${baseUrl}?access-token=${accessToken}`;

  const handleClose = () => {
    onClose();
    if (onLoginShown) {
      snackbar({
        title: lang.m_lbl_yir_snackbar,
        autoHideDuration: 5000,
        open: true,
        hideCancel: true,
        horizontalAlignment: 'center',
      });
    }
  };
  return (
    <>
      <img src={closeIcon} className="close-year-review" onClick={handleClose} alt="Close Year Review" />
      <iframe src={link} title="Year Review Content" className="year-review-popup" />
    </>
  );
};

export default YearReview;
